import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@Store/store";
import { UserState } from "@App/store/reducers/userReducer";
import { useEffect, useMemo, useState } from "react";
import userSession from "@App/auth/userSession";
import {
  getAmenitiesDetails,
  getCommunityAssetAvailableDates,
  getCommunityAssetAvailableRatesAndTimes,
} from "@App/api/amenities";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  QUERY_KEY_USER_RESERVATION_LIST,
  QUERY_KEY_USER_VENUE_AVAILABILITY,
  QUERY_KEY_USER_VENUE_DETAILS,
  QUERY_KEY_USER_VENUES_RATES,
} from "@App/constants/queryKeyConstants";
import {
  SHOW_LOGIN_MODAL,
  SHOW_REGISTRATION_MODAL,
} from "@App/constants/appConstants";
import {
  customEventPublish,
  sendMobileViewModePostMessage,
} from "@Utils/utils";
import { addWeeks, endOfMonth, formatISO, startOfMonth } from "date-fns";
import { PaymentsProps } from "@Components/payments/PaymentsPropTypes";
import { buildPaymentData, findIsCTAButtonDisabled } from "./utils/utils";
import { useRateDropdowns } from "./utils/useRateDropdowns";
import { UserType } from "@App/models/types";
import { SHOW_REGISTRATION_DETAILS } from "@App/constants/userConstants";

const VenuesDetailLogic = () => {
  const queryClient = new QueryClient();
  const navigate = useNavigate();

  const { venueId } = useParams<{ venueId: string }>();

  const storeData = useSelector<
    RootState,
    {
      storeUser: UserState;
    }
  >((state) => {
    return {
      storeUser: state.userProfile,
    };
  });
  const { storeUser } = storeData;

  const userData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );

  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedRate, setSelectedRate] = useState<any>(null);
  const [selectedStartTime, setSelectedStartTime] = useState<any>(null);
  const [selectedEndTime, setSelectedEndTime] = useState<any>(null);

  const [showVenueBookingPayment, setShowVenueBookingPayment] = useState(false);
  const [hasSpaceBooking, setHasSpaceBooking] = useState<boolean | null>(null);
  const [showVenueBooking, setShowVenueBooking] = useState(false);
  const [showReservationRules, setShowReservationRules] = useState(false);
  const [isMobileEmbed] = useState(userSession.isMobileViewMode);

  const [paymentData, setPaymentData] = useState<PaymentsProps>();

  const isUserLogged = useMemo(
    () => !!userData.userInfo?.firstName,
    [userData],
  );

  const userType = useMemo(() => userData.userInfo?.userType, [userData]);

  const isCTAButtonDisabled = findIsCTAButtonDisabled(
    isUserLogged,
    userType!,
    selectedRate,
    selectedStartTime,
    selectedEndTime,
  );

  useEffect(() => {
    if (venueId) {
      try {
        queryClient.invalidateQueries([QUERY_KEY_USER_VENUE_DETAILS, venueId!]);
        queryClient.invalidateQueries([QUERY_KEY_USER_VENUES_RATES, venueId!]);
      } catch (error) {
        console.log(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeUser, venueId]);

  const { data: communityAsset, isLoading: isLoadingCommunityAsset } = useQuery(
    {
      queryKey: [QUERY_KEY_USER_VENUE_DETAILS, venueId!],
      queryFn: () => getAmenitiesDetails(venueId!),
      enabled: !!venueId,
      select: (data) => data.data,
    },
  );

  const { data: availableRates, isLoading: isLoadingRates } = useQuery({
    queryKey: [QUERY_KEY_USER_VENUES_RATES, venueId!, selectedDate!],
    queryFn: () =>
      getCommunityAssetAvailableRatesAndTimes(
        venueId!,
        formatISO(selectedDate!, {
          format: "extended",
          representation: "date",
        }),
      ),
    enabled: !!selectedDate && !!venueId,
    select: (data) => data.data,
  });

  const {
    calendarCurrentMonth,
    handleMonthChange,
    resetDropdowns,
    handleRateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    getRateOptions,
    getStartTimes,
    getEndTimes,
  } = useRateDropdowns({
    rates: availableRates?.rates,
    selectedRate,
    selectedStartTime,
    setSelectedRate,
    setSelectedStartTime,
    setSelectedEndTime,
  });

  const { data: availableDates, isLoading: isLoadingDates } = useQuery({
    queryKey: [
      QUERY_KEY_USER_VENUE_AVAILABILITY,
      venueId!,
      calendarCurrentMonth,
    ],
    queryFn: () =>
      getCommunityAssetAvailableDates(
        venueId!,
        formatISO(addWeeks(startOfMonth(calendarCurrentMonth), -1), {
          format: "extended",
          representation: "date",
        }),
        formatISO(addWeeks(endOfMonth(calendarCurrentMonth), 1), {
          format: "extended",
          representation: "date",
        }),
      ),
    enabled: !!venueId,
    select: (data) => data.data,
  });

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoadingCommunityAsset });
  }, [isLoadingCommunityAsset]);

  useEffect(() => {
    if (communityAsset) {
      const hasReservableSpaces = communityAsset.spaces?.some(
        (sp) => sp.canBeBooked && !!sp.reservation?.setup,
      )!;
      setHasSpaceBooking(hasReservableSpaces);
      setShowVenueBooking(!hasReservableSpaces);
    }
  }, [communityAsset]);

  const goBack = () => {
    navigate(-1);
  };

  const bookingCtaLabel = useMemo(() => {
    if (!!userType || userType === UserType.Initiated) {
      if ([UserType.CommunityMember, UserType.Resident].includes(userType)) {
        return "Proceed";
      }
      if (userType === UserType.Initiated) {
        return "Please Complete Account Setup";
      }
      if (userType === UserType.ResidentPending) {
        return "Resident Verification Pending";
      }
    }
    return "Log In to Proceed";
  }, [userType]);

  const handleNotLoggedUserLogin = () => {
    customEventPublish(SHOW_LOGIN_MODAL, {});
  };

  const handleNotLoggedUserRegister = () => {
    customEventPublish(SHOW_REGISTRATION_MODAL, {});
  };

  const goToVenueBookingPayment = (paymentData?: PaymentsProps) => {
    const paymentContext = paymentData?.paymentData;
    setPaymentData(
      paymentContext ? paymentData :
        buildPaymentData({
          venueId: venueId!,
          communityAsset: communityAsset,
          bookingData: {
            rateId: selectedRate.value,
            rateName: selectedRate.rateName,
            selectedDate: selectedDate?.toISOString()!,
            startTime: selectedStartTime.value,
            endTime: selectedEndTime.value,
            cost: selectedEndTime.cost,
          },
        }),
    );
    setShowVenueBookingPayment(true);
  };

  const showAccountDetails = () => {
    dispatch({
      type: SHOW_REGISTRATION_DETAILS,
      payload: {
        id: userData.userInfo?.id,
        userVerificationCode: userData.userInfo?.userVerificationCode,
      },
    });
  };

  const handleCTAButton = (spacePaymentData?: PaymentsProps) => {
    if (!!userType || userType === UserType.Initiated) {
      if ([UserType.CommunityMember, UserType.Resident].includes(userType)) {
        goToVenueBookingPayment(spacePaymentData);
        return;
      }
      if (userType === UserType.Initiated) {
        showAccountDetails();
        return;
      }
      if (userType === UserType.ResidentPending) {
        return;
      }
    }

    handleNotLoggedUserLogin();
  };

  const onPaymentSuccess = () => {
    queryClient.invalidateQueries([QUERY_KEY_USER_RESERVATION_LIST]);
  };

  return {
    communityAsset,
    isLoadingCommunityAsset,
    availableDates,
    isLoadingDates,
    availableRates,
    isLoadingRates,

    venueId,
    paymentData,

    selectedRate,
    selectedEndTime,
    selectedStartTime,
    selectedDate,
    setSelectedDate,

    bookingCtaLabel,
    handleCTAButton,
    userType,

    isUserLogged,
    isCTAButtonDisabled,
    isMobileEmbed,
    hasSpaceBooking,
    isContentLoading:
      isLoadingCommunityAsset ||
      !communityAsset ||
      hasSpaceBooking == null ||
      (!availableDates && !isLoadingDates),

    showVenueBookingPayment,
    setShowVenueBookingPayment,
    showReservationRules,
    setShowReservationRules,
    showVenueBooking,
    setShowVenueBooking,

    resetDropdowns,
    handleMonthChange,
    handleRateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    handleNotLoggedUserLogin,
    handleNotLoggedUserRegister,
    getRateOptions,
    getStartTimes,
    getEndTimes,
    goBack,
    onPaymentSuccess,
  };
};

export default VenuesDetailLogic;
