import { handleNotLoggedUserLogin } from "@App/store/actions/userActions";
import { Heart } from "@Components/icons";
import {
  BackButton,
  Breadcrumb,
  Button,
  ImageFilesCarousel,
  Loader,
  Tag,
  WaiversModal,
} from "@Components/index";
import { Navbar } from "@Layouts/index";
import { formatCurrency, handleExternalUrl } from "@Utils/utils";
import "./EventDetails.scss";
import EventDetailsLogic from "./EventDetailsLogic";

const EventDetails = () => {
  const {
    event,
    isLoadingEvent,
    eventDateString,
    eventTimeString,
    currentEventCategories,
    waiverItems,
    userInfo,
    ticketingCtaLabel,

    showCTAs,
    isUserLogged,
    isCTAButtonDisabled,
    isFavPreferenceProcessing,
    isRsvpPreferenceProcessing,

    // isTicketPurchaseModalOpen,
    // setIsTicketPurchaseModalOpen,
    isWaiversModalOpen,
    setIsWaiversModalOpen,

    goBack,
    updateEventPreference,
    handleWaiversClose,
    handleCTAButton,
  } = EventDetailsLogic();

  if (isLoadingEvent || !event) {
    return (
      <Navbar>
        <Loader modal text={"Loading event details"} />
      </Navbar>
    );
  }

  return (
    <Navbar>
      <div className={"event-details-container"}>
        <div className={"event-details-breadcrumb"}>
          <Breadcrumb
            initialSection={{ text: "Home", path: "/" }}
            currentSectionName={event.title}
            ignoreArray={["event-details"]}
          />
        </div>
        <div className={"event-details-navigation-min"}>
          <BackButton goBack={goBack} />
        </div>

        <div className={"event-details-text event-details-detail-wrapper"}>
          <div className={"event-details-name"}>{event.title}</div>
          {event.isPinned && (
            <Tag
              backgroundColor={"#ffd800"}
              text={"Signature Event"}
              size={"small"}
              isStatusTag={true}
              className={"event-details-pinned"}
            />
          )}
          <div className={"event-details-date"}>{eventDateString}</div>
          <div>{eventTimeString}</div>
          <div className="event-details-location">
            {event.venue || event.address?.address1}
          </div>
          {event.eventType !== "Admin" && (
            <div className={"event-details-crousel"}>
              <ImageFilesCarousel files={event.files || []} />
            </div>
          )}
          {event.eventType !== "Admin" &&
            isUserLogged &&
            (!event.user?.isRSVPd || event.user?.isAttending) && (
              <div className={"event-details-fav"}>
                {event.user?.isAttending && (
                  <button className={"event-details-fav-button"} disabled>
                    <Tag
                      backgroundColor={"#CFF5D5"}
                      text={"Attending"}
                      isStatusTag={true}
                    />
                  </button>
                )}
                <button
                  className={"event-details-fav-button"}
                  disabled={isFavPreferenceProcessing}
                  style={{ marginTop: isFavPreferenceProcessing ? 5 : 0 }}
                  data-cy="event-details-heart"
                  onClick={async () => {
                    await updateEventPreference(
                      "isFavorite",
                      !event.user?.isFavorite,
                    );
                  }}
                >
                  {isFavPreferenceProcessing ? (
                    <Loader
                      color={"silver"}
                      horizontallyCentered
                      width="20"
                      height="10"
                    />
                  ) : (
                    <Heart isActive={event.user?.isFavorite} />
                  )}
                </button>
              </div>
            )}
          <div className={"event-details-bottom-content"}>
            <div className={"event-details-bottom-content-left"}>
              <div
                className={"event-details-description"}
                dangerouslySetInnerHTML={{
                  __html: event.description ?? "",
                }}
              ></div>
              <div className={"event-details-categories"}>
                {currentEventCategories.map((eventCategory, index) => {
                  return (
                    <div
                      key={`event_category_${index}`}
                      className={"event-details-category"}
                    >
                      <Tag text={eventCategory.name} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className={`event-details-bottom-content-right ${
                isUserLogged ? "event-details-bottom-content-right-logged" : ""
              }`}
              style={{
                justifyContent: event.user?.isRSVPd
                  ? "flex-end"
                  : "space-between",
              }}
            >
              <div
                className={`event-details-top-actions ${
                  isUserLogged && event.user?.isRSVPd
                    ? "event-details-top-actions-rsvpd"
                    : ""
                }`}
              ></div>
              {event.address ? (
                <div className={"event-details-location-bottom"}>
                  <p>Location</p>
                  <p className="event-details-location-bottom-text">
                    {`${event.address.address1} ${event.address.city}, ${event.address.state} ${event.address.zip}`}
                  </p>
                </div>
              ) : null}
              {/* Hide default buttons if there are overrides */}
              {showCTAs &&
                ((event.callsToAction?.length ?? 0) > 0 ? (
                  event.callsToAction?.map((cta, index) => {
                    return (
                      <Button
                        key={`cta_action${index}`}
                        text={cta.displayText}
                        onClick={() => handleExternalUrl(cta.url)}
                        style={{ marginTop: index > 0 ? 10 : 0 }}
                      />
                    );
                  })
                ) : (
                  <>
                    {/* General Event */}
                    {event.eventType !== "Admin" &&
                      event.isRsvpEnabled &&
                      Array.isArray(event.ticketsPrices) &&
                      !event.ticketsPrices.length && (
                        <div className={"event-details-rsvp"}>
                          {!event.user?.isRSVPd ? (
                            isRsvpPreferenceProcessing ? (
                              <Loader color={"silver"} horizontallyCentered />
                            ) : (
                              <Button
                                isLoading={isRsvpPreferenceProcessing}
                                text={isUserLogged ? "RSVP" : "Login to RSVP"}
                                onClick={async () => {
                                  // TODO: Can't use handleCTAButton here because of the waiver check
                                  if (!isUserLogged) {
                                    handleNotLoggedUserLogin();
                                    return;
                                  }

                                  if (event?.waiverIds?.length) {
                                    setIsWaiversModalOpen(true);
                                    return;
                                  }

                                  await updateEventPreference("isRSVPd", true);
                                }}
                              />
                            )
                          ) : isRsvpPreferenceProcessing ? (
                            <Loader color={"silver"} horizontallyCentered />
                          ) : (
                            <Button
                              isLoading={isRsvpPreferenceProcessing}
                              text={"Attending"}
                              isSecondary={true}
                              onClick={async () => {
                                await updateEventPreference("isRSVPd", false);
                              }}
                            />
                          )}
                        </div>
                      )}
                    {/* Ticketed events */}
                    {event.eventType !== "Admin" &&
                      Array.isArray(event.ticketsPrices) &&
                      !!event.ticketsPrices.length && (
                        <div className={"event-details-tickets"}>
                          <div className="event-details-tickets-card">
                            <h3 className="event-details-tickets-card__title">
                              Tickets Prices
                            </h3>
                            <div className="event-details-tickets-card__list">
                              {event?.ticketsPrices?.map(
                                ({ name, price, id }) => (
                                  <div
                                    className="event-details-tickets-card__row"
                                    key={id}
                                  >
                                    <div className="event-details-tickets__type">
                                      {name}
                                    </div>
                                    <div className="event-details-tickets__price">{`${formatCurrency(
                                      price,
                                    )}`}</div>
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                          <Button
                            text={ticketingCtaLabel}
                            onClick={handleCTAButton}
                            isDisabled={isCTAButtonDisabled}
                            dataCy="event-details-purchase-tickets-btn"
                          />
                        </div>
                      )}
                  </>
                ))}
            </div>
          </div>
        </div>

        {/* Just go to the payment page instead of this weird thing */}
        {/* TODO: Saving this for time slotted events */}
        {/* <Modal isVisible={isTicketPurchaseModalOpen}>
            <TicketPurchase
              eventData={currentEvent}
              initialSelectedDate={
                getEventStartAndEndDates(currentEvent).startDate
              }
              onClose={(eventId) => handleTicketPurchase(eventId)}
            />
          </Modal> */}

        {/* Waivers are usually loaded on the payments view. Since RSVP doesn't have that, they load here
            TODO: For the sake of my sanity, and consistency, please remove the payment view waivers and handle them here
            This is why people go mad.
          */}
        <WaiversModal
          userInfo={{
            firstname: userInfo?.firstName ?? "",
            lastname: userInfo?.lastName ?? "",
          }}
          waivers={waiverItems ?? []}
          isVisible={isWaiversModalOpen}
          onContinue={handleWaiversClose}
          ctaText="Continue to Book"
        />
      </div>
    </Navbar>
  );
};

export default EventDetails;
